import { LitElement, html } from 'lit';

import UserSessionController from './user_session_controller';

class TopBar extends LitElement {
  userSessionController = new UserSessionController(this);

  static properties = {
    title: { type: String }
  };

  constructor() {
    super();
    this.title = '';
  }

  createRenderRoot() {
    return this; // Disable Shadow DOM
  }

  handleLoginClick() {
    if (this.userSessionController.isLoggedIn) {
      this.userSessionController.logout();
    } else {
      this.userSessionController.logIn();
    }
  }

  getLinks() {
    const baseLinks = [
      { text: 'Home', href: '/' },
      { text: 'Report Issue', href: '/issue' }
    ];

    if (this.userSessionController.isAdmin) {
      return [
        ...baseLinks,  // Spread operator to include base links
        { text: 'Admin', href: '/admin' }
      ];
    } else {
      return baseLinks;
    }
  }

  render() {
    return html`
        <h1>${this.title}</h1>
        <nav>
          <ul>
            ${this.getLinks().map(link => html`
              <li><a href="${link.href}">${link.text}</a></li>
            `)}
          </ul>
          <ul>
            ${this.userSessionController.isLoggedIn ? this.userSessionController.userDisplayName : 'Guest'} 
            <li>
            </li>
            <li>
              <button @click=${this.handleLoginClick}>
                ${this.userSessionController.isLoggedIn ? 'Logout' : 'Login'}  
              </button>
            </li>
          </ul>
        </nav>
    `;
  }
}

customElements.define('top-bar', TopBar);
