const USER_KEY = 'user';

class UserSessionController {
  host;
  _isLoggedIn = false;
  _isAdmin = false;
  _userEmail = '';

  constructor(host) {
    this.host = host;
    host.addController(this);
  }

  hostConnected() {
    const userData = JSON.parse(sessionStorage.getItem(USER_KEY));
    if (userData) {
      this._prepareSessionFromUser(userData);
    } else {
      this._checkUserSession();
    }
  }

  _prepareSessionFromUser(userData) {
    if (userData) {
      if (userData.email) {
        this._isLoggedIn = true;
        this._isAdmin = userData.roles.includes('admin');
        this._userEmail = userData.email;
      } else {
        // If there are userData, but empty, means we have checked, but the user isn't logged in
        this._isLoggedIn = false;
        this._isAdmin = false;
        this._userEmail = '';
      }
    }
  }

  async _checkUserSession() {
    try {
      const response = await fetch('/auth/check');
      const userData = await response.json();
      console.debug('user from check', userData);

      if (response.ok && userData.email) {
        sessionStorage.setItem(USER_KEY, JSON.stringify(userData));
      } else {
        // Save an empty user object to signify logged out user.
        sessionStorage.setItem(USER_KEY, JSON.stringify({}));
      }
      this._prepareSessionFromUser(userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle errors gracefully (e.g., show an error message)
    } finally {
      this.host.requestUpdate(); // Update the host component after fetching data
    }
  }

  logIn() {
    sessionStorage.removeItem(USER_KEY);
    window.location.href = '/auth/redirect';
  }

  logout() {
    sessionStorage.removeItem(USER_KEY);
    window.location.href = '/auth/logout';
  }

  get isLoggedIn() {
    return this._isLoggedIn;
  }

  get isAdmin() {
    return this._isAdmin;
  }

  get userDisplayName() {
    return this._userEmail;
  }
}

export default UserSessionController;
